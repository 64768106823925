<app-formulario-tabs [formulario]="formulario" [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)" (criadorSelecionado)="criadorSelecionado($event)"></app-formulario-tabs>

<div class="row">

  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">

        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">

                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="baixa"></mat-icon> Dados da Baixa
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button *ngIf="ocorrenciaAtual.Id !== '-1'" type="button" class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()"><i class="far fa-folder"></i> Documentos</button>
                    </div>
                  </div>

                  <div class="row align-items-end">

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">*Data Baixa</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control" [max]="maxDate" [matDatepicker]="dataBaixa" name="DataOcorrencia"
                          [(ngModel)]="ocorrenciaAtual.DataBaixa" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="dataBaixa.open()"> <i
                              class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataBaixa" #dataBaixa></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">*Motivo</label>
                      <select [disabled]="isOcorrenciaReadOnly()" class="form-control form-control-sm" name="Motivo"
                        [(ngModel)]="ocorrenciaAtual.TipoMotivoBaixa">
                        <option *ngFor="let motivo of motivos" [value]="motivo.value" [label]="motivo.label"></option>
                      </select>
                    </div>

                    <div *ngIf="env.contexto == 'wagyu' && ocorrenciaAtual.TipoMotivoBaixa == 52"
                      class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">*Causa</label>
                      <select [disabled]="isOcorrenciaReadOnly()" class="form-control form-control-sm"
                        name="TipoPadreacao" [(ngModel)]="ocorrenciaAtual.MotivoMorte">
                        <option *ngFor="let causa of causasMorteNatural" [value]="causa.value" [label]="causa.label">
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-bovino 
                      [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                      [bovino]="ocorrenciaAtual.Animal">
                      </app-consultar-bovino>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)" tipoEvento="baixa"></app-botoes-acoes-ocorrencia>
            </div>

          </div>

          <!--Lista de Ocorrencias-->
          <div>

            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px;">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Animal</th>
                      <th>Data Baixa</th>
                      <th>Motivo Baixa</th>
                      <th *ngIf="env.contexto === 'wagyu'">Certificado</th>
                      <th *ngIf="env.contexto === 'wagyu'">Motivo Morte</th>
                      <th>Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ev of ocorrencias"
                      [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id }">
                      <td>{{ ev.Animal.NomeBovino }}</td>
                      <td>{{ ev.DataBaixa | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ commons.getDescricaoMotivoBaixa( ev.TipoMotivoBaixa) }}</td>
                      <td *ngIf="env.contexto === 'wagyu'">{{ commons.getCertificadoBaixa( ev.CertificadoBaixa) }}</td>
                      <td *ngIf="env.contexto === 'wagyu'">{{ commons.getDescricaoMotivoMorte( ev.MotivoMorte) }}</td>
                      <td>{{ commons.getDescricaoSituacao( ev.Situacao ) }}</td>
                      <td class="text-center text-nowrap acoes-evento">
                        <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                        <i *ngIf="ev.SolicitarDna === 1" matTooltip="Será solicitado DNA no Nascimento"
                          class="fas fa-dna"> </i>
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                        <i *ngIf="ev.IndInconsitencia" class="fa fa-exclamation-triangle" style="cursor: pointer;"
                          matTooltip="{{ev.Inconsistencia}}"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>