import { AccountService } from 'src/app/account/shared/account.service';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';
import { TipoManejo } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pesagem-manter',
  templateUrl: './pesagem-manter.component.html',
  styleUrls: ['./pesagem-manter.component.scss']
})
export class PesagemManterComponent extends BaseEventos implements OnInit {
  isReadonly: boolean = false;
  readonly env = environment;

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.PESAGEM;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params);
    });
  }

  public getDescTipoManejo(tipo: TipoManejo): string {
    switch (tipo) {
      case TipoManejo.CONFINADO: return "Confinado";
      case TipoManejo.SEMI_CONFINADO: return "Semi Confinado";
      case TipoManejo.PASTO: return "Pasto";
      default: return "";
    }
  }

}
