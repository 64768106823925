import { DadosBovinoComponent } from './../../bovino/bovino-detalhar/dados-bovino/dados-bovino.component';
import { LocalStorageService } from './../local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { AccountService } from 'src/app/account/shared/account.service';
import { FiltroConsultaEventosModel } from 'src/app/models/FiltroConsultaEventosModel';
import { Subscription } from 'rxjs';
import { A } from '@angular/cdk/keycodes';
import { ConstantesTipoEvento } from '../constantes';
import { log } from 'console';
import { SituacaoOcorrenciaEvento, TipoConsultaCarcaca } from '../enums/Enums';

@Component({
  selector: 'app-filtro-consulta-eventos',
  templateUrl: './filtro-consulta-eventos.component.html',
  styleUrls: ['./filtro-consulta-eventos.component.scss']
})
export class FiltroConsultaEventosComponent implements OnInit {

  situacoesEvento: { value: number, label: string }[] = [];
  inscricao: Subscription = new Subscription;
  rotaAnterior: string = "";

  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();
  @Input() formConsulta: FiltroConsultaEventosModel = new FiltroConsultaEventosModel();
  @Input() isCriador: boolean = false;


  constructor(private accountService: AccountService, private route: ActivatedRoute,
    private localStorage: LocalStorageService,) {
  }

  ngOnInit(): void {
    this.preencherSituacoes();
    this.isCriadorFunction(0);
    let token: any = this.accountService.getPayloadToken();
    if (token) {
      this.formConsulta.criador.id = token.CriadorId;
      this.formConsulta.criador.codigo = token.CodigoCriador;
      this.formConsulta.criador.nome = token.NomeCriador;
      this.formConsulta.criador.readonly = this.accountService.isCriador;

      this.criadorSelecionado(this.formConsulta.criador.id);
    }

    this.recuperarFormulario();
    if (history.state) {
      this.recuperarFormularioPainelGerencial();
    }


    this.rotaAnterior = this.route.snapshot.url[0].path;
    if (this.isAbateTela()) {
      this.isCriadorFunction(history.state.Situacao);
    } else if (this.route.snapshot.url[0].path == 'inspecao_consultar' && this.accountService.isCriador) {
      this.isCriador = true;
      this.formConsulta.situacao = 50
    } else if (history.state.Situacao != null) {
      this.isCriador = false;
    } else {
      this.isCriador = false;
      this.formConsulta.situacao = 0;
    }
  }

  preencherSituacoes() {
    this.situacoesEvento = [];
    this.situacoesEvento.push({ value: 0, label: "Todas" });
    this.situacoesEvento.push({ value: 2, label: "Pendente" });
    this.situacoesEvento.push({ value: 5, label: "Cancelado" });
    this.situacoesEvento.push({ value: 50, label: "Aprovado" });
    this.situacoesEvento.push({ value: 51, label: "Aprovado Com Pendências" });
    this.situacoesEvento.push({ value: 52, label: "Rejeitado" });
    this.situacoesEvento.push({ value: 53, label: "Aguardando Aprovação" });
    this.situacoesEvento.push({ value: 54, label: "Aguardando Resolução" });
    this.situacoesEvento.push({ value: 55, label: "Aprovado Pelo Superintendente" });
    this.situacoesEvento.push({ value: 56, label: "Rejeitado Pelo Superintendente" });

    if (this.accountService.isCriador) {
      this.formConsulta.situacao = SituacaoOcorrenciaEvento.Aprovado;
    }
  }

  criadorSelecionado(idCriador: string) {
    this.formConsulta.bovino.IdProprietarioConsulta = idCriador;
  }
  //formulário de navegação
  private recuperarFormulario() {
    if (this.localStorage.get("rotaAnterior") == this.route.snapshot.url[0].path) {
      // Recupera o state passado pela função de consultas do painel gerencial através da rota
      //let state = history.state;
      // Recupera o state da última busca do localStorage
      const queryState = this.localStorage.get("formConsulta");

      // Caso não haja state do form salvo, inicializa vazio
      //if (!state && !queryState) return;

      // Caso não haja state passado pelo painel gerencial, mas exista um form salvo para essa consulta no storage
      // if (!state) {
      //   Object.assign(state, queryState);
      // }

      if (queryState.Periodo) {
        this.formConsulta.eventoDe = queryState.Periodo.De;
        this.formConsulta.eventoAte = queryState.Periodo.Ate;
      }

      if (queryState.Criador)
        Object.assign(this.formConsulta.criador, queryState.Criador)

      if (queryState.Bovino) {
        Object.assign(this.formConsulta.bovino, queryState.Bovino)
      }
      this.formConsulta.situacao = queryState.Situacao || 0;
      this.formConsulta.comunicacaoAte = queryState.ComunicacaoAte;
      this.formConsulta.comunicacaoDe = queryState.ComunicacaoDe;
      this.formConsulta.formularioProtocolo = queryState.FormularioProtocolo;

      this.triggerConsulta.emit();

    } else {
      this.localStorage.remove("formConsulta");
      this.localStorage.remove("page");
    }
  }
  //para o modal que vem do painel gerencial
  private recuperarFormularioPainelGerencial() {
    const state = history.state;
    console.log(state)
    if (!state) return;

    if (state.Periodo) {
      this.formConsulta.eventoDe = state.Periodo.De;
      this.formConsulta.eventoAte = state.Periodo.Ate;
    }
    if (state.Criador)
      Object.assign(this.formConsulta.criador, state.Criador)

    this.formConsulta.situacao = state.Situacao;

    if (state.OcorrenciaCarcaca) {
      this.formConsulta.ocorrenciaCarcaca = state.OcorrenciaCarcaca;
    }
    this.triggerConsulta.emit();
  }

  isAbateTela() {
    if (this.route.snapshot.url[0].path == 'abatecomavalcarcaca_consultar') {
      return true;
    } else {
      return false;
    }
  }

  trocarTelaAbate() {
    this.isCriadorFunction(0);

    this.triggerConsulta.emit();
  }

  isCriadorFunction(situacao: number) {
    if (this.accountService.isCriador && this.formConsulta.ocorrenciaCarcaca == TipoConsultaCarcaca.Pos) {
      this.isCriador = true;
      this.formConsulta.situacao = 50
    } else if (this.formConsulta.ocorrenciaCarcaca == TipoConsultaCarcaca.Pre) {
      this.isCriador = false;
      return;
    } else {
      this.isCriador = false;
      this.formConsulta.situacao = situacao;
    }
  }
}

