import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { EnderecoModel } from "../models/EnderecoModel";
import { NumeroTelefonicoModel } from "../models/NumeroTelefonicoModel";
import { EnumTipoEventoSRG, TipoInspecao, TipoMotivoMorteNatural } from "./enums/Enums";
import { IEndereco, IModalConfirmacaoOptions, ITelefone } from "./interfaces/Interfaces";

@Injectable({
  providedIn: 'root'
})
export class Commons {

  readonly contexto = (environment as any).contexto;

  getNomeIcone(evento: number) {
    let data = {
      nome: '',
      icone: '',
      tipo: ''
    }
    switch (evento) {
      case 50: data = { nome: this.contexto === 'jersey' ? 'Padreação' : 'Cobertura', icone: "padreacao", tipo: 'svg' }; break;
      case 51: data = { nome: 'Nascimento', icone: "bovino", tipo: 'svg' }; break;
      case 52: data = { nome: 'FIV', icone: "fiv", tipo: 'svg' }; break;
      case 53: data = { nome: this.contexto === 'jersey' ? 'Inovulação' : 'Implantação', icone: "inovulacao", tipo: 'svg' }; break;
      case 54: data = { nome: 'Baixa', icone: "baixa", tipo: 'svg' }; break;
      case 55: data = { nome: 'Transferência', icone: "swap_horiz", tipo: 'material' }; break;
      case 56: data = { nome: 'Inspeção', icone: "inspecao", tipo: 'svg' }; break;
      case 57: data = { nome: 'Premiação', icone: "bovino", tipo: 'svg' }; break;
      case 58: data = { nome: 'Nacionalização', icone: "nacionalizacao", tipo: 'svg' }; break;
      case 59: data = { nome: 'Nacionalização de Embrião', icone: "nacionalizacao", tipo: 'svg' }; break;
      case 60: data = { nome: 'Classificação Linear', icone: "account_tree", tipo: 'material' }; break;
      case 61: data = { nome: 'Pesagem', icone: 'balanca', tipo: 'svg' }; break;
      case 62: data = { nome: 'Abate com Avaliação de Carcaça', icone: 'controle_carcaca', tipo: 'svg' }; break;
      case 63: data = { nome: 'Pós Abate', icone: 'baixa', tipo: 'svg' }; break;
    }
    return data;
  }

  getDescricaoSituacao(evento: number) {
    let data = '';

    switch (evento) {
      case 0: data = 'Todas'; break;
      case 2: data = 'Pendente'; break;
      case 5: data = 'Cancelado'; break;
      case 50: data = 'Aprovado'; break;
      case 51: data = 'Aprovado Com Pendências'; break;
      case 52: data = 'Rejeitado'; break;
      case 53: data = 'Aguardando Aprovação'; break;
      case 54: data = 'Aguardando Resolução'; break;
      case 55: data = 'Aprovado Pelo Superintendente'; break;
      case 56: data = 'Rejeitado Pelo Superintendente'; break;
      case 58: data = 'Aguardando Aprovação Veterinário'; break;
      case 59: data = 'Rejeitado Pelo Veterinário'; break;
    }
    return data;
  }

  getDescricaoSituacaoLote(lote: number) {
    let data = '';

    switch (lote) {
      case 1: data = 'Em Elaboração'; break;
      case 2: data = 'Protocolado'; break;
      case 3: data = 'Processado'; break;
      case 4: data = 'Processado Com Pendências'; break;
      case 5: data = 'Cancelado'; break;
      case 6: data = 'Rejeitado'; break;
    }
    return data;
  }

  getDescricaoSituacaoSRG(situacaoSRG: number) {
    let data = '';

    switch (situacaoSRG) {
      case 1: data = "Pendente de Documentação"; break;
      case 2: data = "Pendente de Registro"; break;
      case 3: data = "Registro Provisório Emitido"; break;
      case 4: data = "Registro Definitivo Emitido"; break;
      case 5: data = "Pendente de Emissão de Registro Provisório"; break;
      case 6: data = "Pendente de Emissão de Registro Definitivo"; break;
    }
    return data;
  }

  getDescricaoClassifcacaoLinearClasse(Classe: number) {
    let data = '';

    switch (Classe) {
      case 1: data = 'EX'; break;
      case 2: data = 'EX'; break;
      case 3: data = 'EX'; break;
      case 4: data = 'MB'; break;
      case 5: data = 'B+'; break;
      case 6: data = 'B'; break;
      case 7: data = 'R'; break;
      case 8: data = 'P'; break;
    }
    return data;
  }

  getDescricaoTipoInspecao(tipoInspecao: number) {
    switch (tipoInspecao) {
      case TipoInspecao.Avaliacao: return 'Avaliação';
      case TipoInspecao.InspecaoDeRegistro: return 'Inspeção de Registro';
      default: return '';
    }
  }

  getDescricaoSituacaoCriador(situacao: number) {
    let data = '';

    switch (situacao) {
      case 1: data = 'Ativo'; break;
      case 2: data = 'Inativo'; break;
    }
    return data;
  }

  getDescricaoApto(aptoRegistro: number) {
    let data = '';

    switch (aptoRegistro) {
      case 1: data = 'Sim'; break;
      case 2: data = 'Não'; break;
      case 3: data = 'Não avaliado'; break;
    }
    return data;
  }

  getDescricaoMotivoBaixa(aptoRegistro: number) {
    let data = '';

    switch (aptoRegistro) {
      case 52: data = 'Morte Natural'; break;
      case 50: data = 'Descarte'; break;
      case 51: data = 'Envenenamento'; break;
      case 53: data = 'Picada de Cobra'; break;
      case 54: data = 'Baixa por Venda'; break;
      case 55: data = 'Abate/Frigorífico'; break;
    }
    return data;
  }

  getDescricaoMotivoMorte(codigo: number): string {
    switch (codigo) {
      case TipoMotivoMorteNatural.ATAQUE_ONCA: return 'Ataque Onça';
      case TipoMotivoMorteNatural.ACIDENTE: return 'Acidente';
      case TipoMotivoMorteNatural.AFOGAMENTO: return 'Afogamento';
      case TipoMotivoMorteNatural.CHOQUE_ELETRICO: return 'Choque Elétrico';
      case TipoMotivoMorteNatural.RAIO: return 'Raio';
      case TipoMotivoMorteNatural.PICADA_DE_COBRA: return 'Picada de Cobra';
      case TipoMotivoMorteNatural.ABORTO: return 'Aborto';
      case TipoMotivoMorteNatural.NATIMORTO: return 'Natimorto';
      case TipoMotivoMorteNatural.PARTO: return 'Parto';
      case TipoMotivoMorteNatural.DIARREIA: return 'Diarreia';
      case TipoMotivoMorteNatural.PNEUMONIA: return 'Pneumonia';
      case TipoMotivoMorteNatural.TETANO: return 'Tetano';
      case TipoMotivoMorteNatural.BABESIOSE: return 'Babesiose';
      case TipoMotivoMorteNatural.GANGRENA: return 'Gangrena';
      case TipoMotivoMorteNatural.HEMORRAGIA: return 'Hemorragia';
      case TipoMotivoMorteNatural.INFECCAO: return 'Infecção';
      case TipoMotivoMorteNatural.ANEMIA: return 'Anemia';
      case TipoMotivoMorteNatural.CARBUNCULO: return 'Carbunculo';
      case TipoMotivoMorteNatural.COLICA: return 'Colica';
      case TipoMotivoMorteNatural.PERITONITE: return 'Peritonite';
      case TipoMotivoMorteNatural.INSUFICIENCIA_RESPIRATORIA: return 'Insuficiencia Respiratória';
      case TipoMotivoMorteNatural.INTOXICACAO: return 'Intoxicação';
      case TipoMotivoMorteNatural.FRACO: return 'Fraco';
      case TipoMotivoMorteNatural.EMPANZINAMENTO: return 'Empanzinamento';
      case TipoMotivoMorteNatural.INSUFICIENCIA_RENAL: return 'Insuficiencia Renal';
      case TipoMotivoMorteNatural.DESIDRATACAO: return 'Desidratação';
      case TipoMotivoMorteNatural.ROMPIMENTO_ALCA_INTESTINAL: return 'Rompimento Alça Intestinal';
      case TipoMotivoMorteNatural.PROLAPSO_UTERINO: return 'Prolapso Uterino';
      case TipoMotivoMorteNatural.ALEIJADO: return 'Aleijado';
      case TipoMotivoMorteNatural.QUEDA: return 'Queda';
      case TipoMotivoMorteNatural.TRISTEZA: return 'Tristeza';
      case TipoMotivoMorteNatural.ROUBADO: return 'Roubado';
      case TipoMotivoMorteNatural.ABATE_FRIGORIFICO: return 'Abate / frigorífico';
      case TipoMotivoMorteNatural.CRUZAMENTO_INDUSTRIAL: return 'Cruzamento Industrial';
      case TipoMotivoMorteNatural.CLOSTRIDIOSE: return 'Clostridiose';
      case TipoMotivoMorteNatural.LIPOMA: return 'Lipoma';
      case TipoMotivoMorteNatural.PIROPLASMOSE: return 'Piroplasmose';
      case TipoMotivoMorteNatural.DESCARTE: return 'Descarte';
      case TipoMotivoMorteNatural.LEUCOSE: return 'Leucose';
      case TipoMotivoMorteNatural.HEPATOPATIA: return 'Hepatopatia';
      case TipoMotivoMorteNatural.DESCONHECIDO: return 'Desconhecido';
      default: return '';
    }
  }

  getCertificadoBaixa(CertificadoBaixa: number) {
    let data = '';
    switch (CertificadoBaixa) {
      case 1: data = 'Aprovado'; break;
      case 2: data = 'Reprovado'; break;
    }
    return data;
  }

  getTipoRegistro(TipoRegistro: number) {
    let data = '';
    switch (TipoRegistro) {
      case 1: data = 'Nascimento'; break;
      case 2: data = 'Aborto'; break;
      case 3: data = 'Natimorto'; break;
    }
    return data;
  }
  getDescricaoSexo(Sexo: number) {
    let data = '';
    switch (Sexo) {
      case 1: data = 'Macho'; break;
      case 2: data = 'Fêmea'; break;
    }
    return data;
  }

  getTipoTransferencia(tipoTransferencia: number) {
    let data = '';
    switch (tipoTransferencia) {
      case 1: data = 'Coleta Com Implante Total'; break;
      case 2: data = 'Coleta Com Congelamento Total'; break;
      case 3: data = 'Coleta Com Implante E Congelamento'; break;
      case 4: data = 'Descongelamento E Implante'; break;
    }
    return data;
  }

  getPrenhez(prenha: number) {
    let data = '';
    switch (prenha) {
      case 1: data = 'Sim'; break;
      case 2: data = 'Não'; break;
    }
    return data;
  }

  getTransferenciaMotivo(TransferenciaMotivo: number) {
    let data = '';
    switch (TransferenciaMotivo) {
      case 1: data = 'Venda'; break;
      case 2: data = 'Troca'; break;
      case 3: data = 'Doação'; break;
      case 4: data = 'Cessão'; break;
    }
    return data;
  }

  getTipoTransferenciaAnimal(TipoTransferenciaAnimal: number) {
    let data = '';
    switch (TipoTransferenciaAnimal) {
      case 1: data = 'Temporária'; break;
      case 2: data = 'Definitiva'; break;
    }
    return data;
  }

  base64toBlob(b64Data: string, contentType: string = 'contentType'): Blob {
    const sliceSize = 512;
    const byteArrays = [];
    const byteCharacters = atob(b64Data);

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  visualizarBase64EmPdf(content: string, type: string) {
    const byteArray = new Uint8Array(atob(content).split('').map(char => char.charCodeAt(0)));
    const blob: Blob = new Blob([byteArray], { type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  async modalConfirmacao(options: IModalConfirmacaoOptions): Promise<boolean> {
    return Swal.fire({
      icon: 'warning',
      focusCancel: true,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      ...options
    }).then(result => result.isConfirmed);
  }

  public converterEndereco(objeto: EnderecoModel, apiResult: IEndereco): void {
    objeto.uf = apiResult.UF;
    objeto.cep = apiResult.Cep;
    objeto.cidade = apiResult.Cidade;
    objeto.bairro = apiResult.Bairro;
    objeto.numero = apiResult.Numero;
    objeto.logradouro = apiResult.Logradouro;
    objeto.complemento = apiResult.Complemento;
  }

  public converterTelefone(objeto: NumeroTelefonicoModel, apiResult: ITelefone): void {
    objeto.ddd = apiResult.DDD;
    objeto.tipo = apiResult.Tipo;
    objeto.ramal = apiResult.Ramal;
    objeto.numero = apiResult.Numero;
  }

  public getDescricaoEvento(tipo: EnumTipoEventoSRG): string {
    switch (tipo) {
      case EnumTipoEventoSRG.Baixa: return 'Baixa';
      case EnumTipoEventoSRG.ClassificacaoLinear: return 'Classificação Linear';
      case EnumTipoEventoSRG.Nacionalizacao: return 'Nacionalização';
      case EnumTipoEventoSRG.NacionalizacaoEmbriao: return 'Nacionalização Embrião';
      case EnumTipoEventoSRG.Nascimento: return 'Nascimento';
      case EnumTipoEventoSRG.Transferencia: return 'Transferência';
      case EnumTipoEventoSRG.FIV: return 'FIV';
      case EnumTipoEventoSRG.Premiacao: return 'Premiação';
      case EnumTipoEventoSRG.Inspecao: return 'Inspeção';
      case EnumTipoEventoSRG.Padreacao: return `${(environment as any).contexto === 'jersey' ? 'Padreação' : 'Cobertura'}`;
      case EnumTipoEventoSRG.Inovulacao: return `${(environment as any).contexto === 'jersey' ? 'Inovulação' : 'Implantação'}`;
      case EnumTipoEventoSRG.Carcaca: return 'Carcaça';
      default: return '';
    }
  }

  public replaceAll = (str: string, find: string, replace: string, caseSensitive: boolean = false) =>
    str.replace(new RegExp(find, caseSensitive ? 'ig' : 'g'), replace);

  isNullOID(id: string | number): boolean {
    !id ?? true;
    let stringId: string = String(id);
    return stringId === '-1' || stringId === '';
  }
}
